import { Button } from '@/components/ui/button';
import Head from 'next/head';
import Link from 'next/link';

export default function NotFoundPage() {
  return (
    <>
      <Head>
        <title>404 | Something Went Wrong</title>
      </Head>
      <div className="min-h-screen w-full overflow-x-hidden bg-background">
        {/* Grid Background */}
        <div className="pointer-events-none fixed inset-0">
          <div className="relative left-1/2 h-full w-[max(100vw,1800px)] -translate-x-1/2">
            {/* Horizontal lines */}
            <div className="absolute top-[33.33%] w-full border-t border-dashed border-gray-200" />
            <div className="absolute top-[66.66%] w-full border-t border-dashed border-gray-200" />
            <div className="absolute bottom-0 w-full border-t border-dashed border-gray-200" />

            {/* Vertical lines */}
            <div
              className="absolute left-[33.33%] h-full border-l border-dashed border-gray-200"
              style={{ minWidth: 'calc(33.33vw + 200px)' }}
            />
            <div
              className="absolute left-[66.66%] h-full border-l border-dashed border-gray-200"
              style={{ minWidth: 'calc(33.33vw + 200px)' }}
            />
            <div className="absolute right-0 h-full border-l border-dashed border-gray-200" />

            {/* Grid intersection dots */}
            <div className="absolute left-[33.33%] top-[33.33%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute left-[66.66%] top-[33.33%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute right-0 top-[33.33%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />

            <div className="absolute left-[33.33%] top-[66.66%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute left-[66.66%] top-[66.66%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute right-0 top-[66.66%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />

            <div className="absolute bottom-0 left-[33.33%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute bottom-0 left-[66.66%] h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
            <div className="absolute bottom-0 right-0 h-3 w-3 -translate-x-1.5 -translate-y-1.5 rounded-full bg-gray-200" />
          </div>
        </div>

        {/* Content - Centered in middle column */}
        <div className="relative flex min-h-screen items-center justify-center">
          <div className="absolute left-1/2 w-[590px] -translate-x-1/2">
            <div className="text-center">
              <h1 className="mb-6 text-[64px] font-bold leading-tight text-foreground">
                404 Error
              </h1>

              <p className="mb-8 text-[16px] leading-relaxed text-[#171717]">
                We&apos;re experiencing a bit of turbulence, but don&apos;t
                worry — our team is already on it and working hard to fix the
                issue.
              </p>

              <div className="flex flex-wrap items-center justify-center gap-2">
                <Link
                  href="https://cal.com/kartik-sharma/creatr-review"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="h-[33px] whitespace-nowrap rounded-full bg-black px-5 text-[14px] text-white hover:bg-black/90">
                    Schedule A Call <span className="ml-1">→</span>
                  </Button>
                </Link>

                <span className="mt-2 text-blue-400">*</span>

                <Link href="https://getcreatr.xyz">
                  <Button
                    variant="ghost"
                    className="h-[33px] whitespace-nowrap rounded-full bg-[#F8F8F8] px-5 text-[14px] text-foreground hover:bg-[#F8F8F8]/80"
                  >
                    Go To Website
                  </Button>
                </Link>

                <span className="mt-2 text-blue-400">*</span>

                <Link href="https://x.com/getcreatr">
                  <Button
                    variant="ghost"
                    className="h-[33px] whitespace-nowrap rounded-full bg-[#F8F8F8] px-5 text-[14px] text-foreground hover:bg-[#F8F8F8]/80"
                  >
                    <span className="line-through">Twitter</span> X
                  </Button>
                </Link>

                <span className="mt-2 text-blue-400">*</span>

                <Link href="https://www.linkedin.com/company/getcreatrurl">
                  <Button
                    variant="ghost"
                    className="h-[33px] whitespace-nowrap rounded-full bg-[#F8F8F8] px-5 text-[14px] text-foreground hover:bg-[#F8F8F8]/80"
                  >
                    LinkedIn
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
